import React, { useState } from "react"
import Modal from 'react-awesome-modal';
import { navigate } from "gatsby"

import { Layout, SEO } from "../components"
import styles from "./about-css-module.module.scss"
import abtBanner from "../../static/abtBanner.png"
import webillustration from "../../static/webillustration.svg"
import leaderClose from "../../static/leaderClose.svg"
import arrowBlack from "../../static/arrowBlack.svg"
import LeaderDeatils from '../components/common/Leadership/LeaderDetails'
import LeadershipTeam from '../components/common/Leadership/LeadershipTeam'
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

const About = (props) => {

  const data = useStaticQuery(graphql`
    query {
      allFile(
        sort: { fields: name }
        filter: { relativeDirectory: { eq: "leadership" } }
      ) {
        edges {
          node {
            id
            name
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
  `)

  console.log('$$data',data);

  let leaderImages = {};
  data.allFile.edges.forEach((leader) => {
      leaderImages[leader.node.name] = leader.node.childImageSharp.fluid;
  })

  const [visible, setVisible] = useState(false);
  const [leaderName, setLeaderName] = useState('');
  const width = typeof window !== 'undefined' ? window?.innerWidth 
          || document?.documentElement.clientWidth
          || document?.body.clientWidth : 501;
  const modalHeight = width > 920 ? '' : '86%';
  const modalwidth = width > 920 ? '80%' : '90%';
  function openModal(name) {
    setLeaderName(name);
    setVisible(true);
    document.body.style.overflow = 'hidden';
  }

  function closeModal() {
    setVisible(false);
    document.body.style.overflow = 'unset';
  }

  function handleClickNews() {
    navigate("/media/press")
  }

  function handleClickCareers() {
    navigate("/careers")
  }

  function navigateRightHandler() {
    let currentIndex = LeadershipTeam.findIndex(x => x.name === leaderName);
    if(currentIndex >= LeadershipTeam.length - 1) {
      setLeaderName(LeadershipTeam[0].name)
    } else {
      setLeaderName(LeadershipTeam[currentIndex + 1].name)
    } 
  }

  function navigateLeftHandler() {
    let currentIndex = LeadershipTeam.findIndex(x => x.name === leaderName);
    console.log('$$current leftindex', currentIndex, LeadershipTeam)
    if(currentIndex === 0) {
      setLeaderName(LeadershipTeam[LeadershipTeam.length -1].name)
    } else {
      setLeaderName(LeadershipTeam[currentIndex - 1].name)
    }
  }

  return (
    <>
      <Layout>
      <SEO 
      title="Solv B2B platform| E-Commerce Marketplace| Credit for MSME" 
      description="Solv is a B2B e-commerce marketplace for SMEs. It facilitates commerce while easing access to finance & business services through a seamless digital experience."
      />
        <section className={styles.container}>
          <div className={styles.about}>
            <img src={abtBanner} alt="logo" />
            <div className={styles.growthPlatform}>
              <div className={[styles.heading, "heading1SemiBold"].join(" ")}>Our Vision</div>
              <div className={styles.description}>
                Improving livelihoods of MSMEs in India and simplifying growth for them.
              </div>
              <div className={styles.description}>
                Solv aims to accelerate growth for India’s 60Mn+ MSMEs by
                leveraging technology and data.
              </div>
              <div className={styles.description}>
                Solv is headquartered in Bangalore (India).
              </div>
            </div>
          </div>

          <div className={styles.ourValueSectionBg}>
            <div className={[styles.marketplace, "heading1Medium"].join(" ")}>Our Values</div>
            <div className={styles.marketDetail}>
            We at Solv believe that strong core values are the foundation of an inspirational business model. Our people and customers are at the centre of everything that we do. In our endeavour to ensure that we prioritize them, our values act as the guiding light. They give focus and direction to the team, helping them work toward the larger common goal. Our values define the way of life for Solvers.
            Our values are interwoven throughout the cultural tapestry of Solv.  Our culture keeps evolving with the contributions that each person makes. We believe that culture is a melting pot of personal beliefs and values that each Solver brings to the family. Each one of us hold these values paramount and live by them, every day. Our values are the guiding principles for acceptable behaviour at work and help us go from strength to strength.
            Internalizing these standards has resulted in a powerful team of Solvers that are immensely dedicated to catering to the needs of our customers.
            </div>

            <div className={styles.ourValue}>
              <div className={styles.our_value_individual}>
                <div className={styles.value_section}>
                  <div className={styles.value_count}>
                    <span className={styles.value_numeric}>1</span>
                  </div>
                  <div className={styles.value_text}>
                    <span className={styles.value_text_desc}>Customer First</span>
                  </div>
                </div>
              </div>
              <div className={styles.our_value_individual}>
                <div className={styles.value_section}>
                  <div className={styles.value_count}>
                    <span className={styles.value_numeric}>2</span>
                  </div>
                  <div className={styles.value_text}>
                    <span className={styles.value_text_desc}>Candor</span>
                  </div>
                </div>
              </div>
              <div className={styles.our_value_individual}>
                <div className={styles.value_section}>
                  <div className={styles.value_count}>
                    <span className={styles.value_numeric}>3</span>
                  </div>
                  <div className={styles.value_text}>
                    <span className={styles.value_text_desc}>Execution Focused</span>
                  </div>
                </div>
              </div>
              <div className={styles.our_value_individual}>
                <div className={styles.value_section}>
                  <div className={styles.value_count}>
                    <span className={styles.value_numeric}>4</span>
                  </div>
                  <div className={styles.value_text}>
                    <span className={styles.value_text_desc}>Have Fun</span>
                  </div>
                </div>
              </div>
              <div className={styles.our_value_individual}>
                <div className={styles.value_section}>
                  <div className={styles.value_count}>
                    <span className={styles.value_numeric}>5</span>
                  </div>
                  <div className={styles.value_text}>
                    <span className={styles.value_text_desc}>Productize</span>
                  </div>
                </div>
              </div>
              <div className={styles.our_value_individual}>
                <div className={styles.value_section}>
                  <div className={styles.value_count}>
                    <span className={styles.value_numeric}>6</span>
                  </div>
                  <div className={styles.value_text}>
                    <span className={styles.value_text_desc}>Think Big Move Fast Start Small</span>
                  </div>
                </div>
              </div>
              <div className={styles.our_value_individual}>
                <div className={styles.value_section}>
                  <div className={styles.value_count}>
                    <span className={styles.value_numeric}>7</span>
                  </div>
                  <div className={styles.value_text}>
                    <span className={styles.value_text_desc}>We Win or Lose Together</span>
                  </div>
                </div>
              </div>
              <div className={styles.our_value_individual}>
                <div className={styles.value_section}>
                  <div className={styles.value_count}>
                    <span className={styles.value_numeric}>8</span>
                  </div>
                  <div className={styles.value_text}>
                    <span className={styles.value_text_desc}>Be Frugal</span>
                  </div>
                </div>
              </div>
              <div className={styles.our_value_individual}>
                <div className={styles.value_section}>
                  <div className={styles.value_count}>
                    <span className={styles.value_numeric}>9</span>
                  </div>
                  <div className={styles.value_text}>
                    <span className={styles.value_text_desc}>Respect, Humility, Integrity</span>
                  </div>
                </div>
              </div>
              <div className={styles.our_value_individual}>
                <div className={styles.value_section}>
                  <div className={styles.value_count}>
                    <span className={styles.value_numeric}>10</span>
                  </div>
                  <div className={styles.value_text}>
                    <span className={styles.value_text_desc}>Empathy</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.marketSectionBg}>
            <div className={[styles.marketplace, "heading1Medium"].join(" ")}>The Solv Marketplace</div>
            <div className={styles.marketDetail}>
              Solv’s B2B commerce platform offers India’s MSME sector an open and inclusive trade ecosystem across the entire value chain. The platform facilitates connections and negotiations with verified sellers and buyers, door-step pick-up and delivery of goods, timely and easy access to finance as well as simplified business support functions. These are critical elements that help expand market access, bring operational efficiencies, and shape success for millions of small businesses across the country.
            </div>
            {/* <div className={styles.marketDetails}>
              {" "}
              Solv’s intuitive and user-friendly conversational commerce platform brings the power of technologies like blockchain, artificial intelligence and machine learning – to small businesses and helps them conduct their business with multiple stakeholders in a trusted environment.
            </div> */}
            <img src={webillustration} alt="logo" />
          </div>
        </section>
        {/* <section className={styles.sectionMargin}>
          <div className={[styles.marketplace, "heading1Medium"].join(" ")}> Leadership</div>
          <div className={styles.leadershipBg}>
            <div className={styles.leadership}>
              <div className={styles.avatar} onClick={() => openModal('Amit Bansal', 1)}>
                 <Img 
                    fluid={leaderImages['amit']}
                  />
                <div className={styles.name}>Amit Bansal</div>
                <div className={styles.designation}>Chief Executive Officer & Board Member</div>
              </div>
            </div>

          </div>
        </section> */}
        <section className={styles.sectionMargin}>
          <div className={[styles.marketplace, "heading1Medium"].join(" ")}>Board Members</div>
          <div className={styles.advisor}>
            {/* <div className={styles.avatars} >
              <Img 
                    fluid={leaderImages['zarin']}
                  />
              <div className={styles.name}>Zarin Daruwala </div>
              <div className={styles.designation}> CEO - Standard Chartered Bank, India</div>
            </div> */}

            <div className={styles.avatars} >
              <Img 
                    fluid={leaderImages['gautam']}
                  />
              <div className={styles.name}>Gautam Jain </div>
              <div className={styles.designation}> Member - SC Ventures</div>
            </div>

            <div className={styles.avatars} >
              <Img 
                    fluid={leaderImages['Nicholas']}
                  />
              <div className={styles.name}>Nicholas Gilbert </div>
              <div className={styles.designation}>
              Global Head, CFCC, CPBB & AME - Standard Chartered Bank
              </div>
            </div>

            <div className={styles.avatars} >
              <Img 
                    fluid={leaderImages['manu']}
                  />
              <div className={styles.name}>Mr. Manu Anand </div>
              <div className={styles.designation}>
                  Independent Director
              </div>
            </div>


            {/* <div className={styles.noshow} >
              <Img 
                    fluid={data.allFile.edges[8].node.childImageSharp.fluid}
                  />
              <div className={styles.name}>Mr. Manu Anand </div>
              <div className={styles.designation}>
              Independent Director
              </div>
            </div> */}

            <div className={styles.avatars} >
              <Img 
                    fluid={leaderImages['sanjay']}
                  />
              <div className={styles.name}>Mr. Sanjay Gurjar </div>
              <div className={styles.designation}>
                MD & Co-Head Client Coverage - India & South Asia, Corporate, Commercial & Institutional Banking (SCB)
              </div>
            </div>

            
            
          </div>
          

          <div className={styles.join}>
            <div className={styles.news} onClick={handleClickNews}>
              <div className={styles.heading}>Solv in the news</div>
              <img src={arrowBlack} alt="arrow" />
            </div>
            <div className={styles.growing} onClick={handleClickCareers}>
              <div className={styles.heading}>Join our growing team</div>
              <img src={arrowBlack} alt="arrow" />
            </div>
          </div>
        </section>
        
        <Modal portalClassName="modal" overflow="scroll" visible={visible} width={modalwidth} height={modalHeight} effect="fadeInDown" onClickAway={() => closeModal()}>
          <div>
            <div className={styles.modal_close_button}  onClick={() => closeModal()}>
              <img className={styles.modalClose} src={leaderClose} alt="logo" />
            </div>
            <div className={styles.modal_content}>
              <LeaderDeatils name={leaderName} navigateLeft={navigateLeftHandler} navigateRight={navigateRightHandler}/>
            </div>
          </div>
        </Modal>

      </Layout>
    </>
  )
}

export default About
